<template>
	<transition>
		<div>
			<div class="page_search_box line_bottom_1px pb-0 mb-0">
				<DxValidationGroup ref="searchValidationGroup">
					<div class="flex space-x-2 inner">
						<div class="mt-2">
							기준년월
						</div>
						<DxDateBox
							:edit-enabled="false"
							styling-mode="outlined"
							width="100"
							height="30"
							v-model="searchType.customTypes.startDt"
							type="date"
							display-format="yyyy.MM"
							dateSerializationFormat="yyyyMMdd"
							dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
							:max="searchType.customTypes.endDt"
							maxLength="7"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
							:calendar-options="{
								zoomLevel: 'year',
								minZoomLevel: 'year',
								maxZoomLevel: 'year',
							}"
						>
							<DxValidator>
								<DxRequiredRule message="기준년월은 필수입니다." />
							</DxValidator>
						</DxDateBox>
						<DxDateBox
							styling-mode="outlined"
							width="100"
							height="30"
							v-model="searchType.customTypes.endDt"
							type="date"
							display-format="yyyy.MM"
							dateSerializationFormat="yyyyMMdd"
							dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
							maxLength="7"
							:min="searchType.customTypes.startDt"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
							:calendar-options="{
								zoomLevel: 'year',
								minZoomLevel: 'year',
								maxZoomLevel: 'year',
							}"
						>
							<DxValidator>
								<DxRequiredRule message="기준년월은 필수입니다." />
							</DxValidator>
						</DxDateBox>
						<DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
					</div>
				</DxValidationGroup>
			</div>

			<div class="cusmain-table-wrap">
				<DxDataGrid
					class="grid-box"
					ref="stdMetricsGrid"
					key-expr="kpiPerformStdId"
					:data-source="dataGrid.dataSource"
					:selected-row-keys="dataGrid.selectedRowKeys"
					:allow-column-resizing="true"
					column-resizing-mode="widget"
					:show-borders="false"
					:show-column-lines="true"
					:show-row-lines="true"
					:remote-operations="true"
					:no-data-text="this.$_msgContents('CMN_NO_DATA')"
					height="500"
					@selection-changed="onSelectionChanged"
				>
					<DxPaging :enabled="false" />
					<DxLoadPanel :enabled="true" />
					<DxScrolling mode="standard" />
					<DxSelection
						v-if="dataGrid.selecting"
						:mode="dataGrid.selecting.mode"
						:show-check-boxes-mode="dataGrid.selecting.showCheckBoxesMode"
						:select-all-mode="dataGrid.selecting.selectAllMode"
					/>
					<DxFilterRow :visible="true" />
					<DxHeaderFilter :visible="true" />
					<DxGroupPanel :visible="false" />

					<DxColumn
						caption="기준년월"
						data-field="ymd"
						data-type="date"
						width="120"
						:allowEditing="false"
						alignment="left"
						:visible="true"
						format="yyyy.MM"
					/>
					<DxColumn
						caption="성과관리기준"
						data-field="performStdNm"
						alignment="left"
						:allow-header-filtering="false"
						:visible="true"
					/>
					<DxColumn caption="배점" data-field="score" width="120" alignment="center" :visible="true" />

					<DxMasterDetail :enabled="true" template="masterDetailTemplate" />

					<template #masterDetailTemplate="{ data: proc }">
						<MasterGridTemplate :server="proc.data" />
					</template>
				</DxDataGrid>
			</div>
		</div>
	</transition>
</template>

<script>
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxButton } from 'devextreme-vue/button';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxValidationGroup } from 'devextreme-vue/validation-group';
import { cloneObj, getPastFromToday, isSuccess } from '@/plugins/common-lib';
import {
	DxDataGrid,
	DxColumn,
	DxMasterDetail,
	DxSelection,
	DxFilterRow,
	DxPaging,
	DxHeaderFilter,
	DxScrolling,
	DxLoadPanel,
	DxGroupPanel,
} from 'devextreme-vue/data-grid';
import MasterGridTemplate from '@/pages/ewm/kpi/implementation/perform-sche/modal-master-grid.vue';

let vm = this;

export default {
	components: {
		DxDateBox,
		DxButton,
		DxRequiredRule,
		DxValidator,
		DxValidationGroup,
		DxDataGrid,
		DxColumn,
		DxMasterDetail,
		DxSelection,
		DxFilterRow,
		DxPaging,
		DxHeaderFilter,
		DxScrolling,
		DxLoadPanel,
		DxGroupPanel,
		MasterGridTemplate,
	},
	props: {
    contentData: Array,
		searchParams: Object,
	},
	watch: {
		'dataGrid.selectedRowsData': {
			//data with key and list to send to parent component
			handler(val) {
				this.$emit('input', val);
			},
			deep: true,
		},
	},
	data() {
		return {
			selectedRows: [],
			searchType: {
				obj: {},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					startDt: getPastFromToday(3, 'months'),
					endDt: getPastFromToday(-3, 'months'),
				},
				paramsData: {},
			},
			dataGrid: {
				dataSource: [],
				selectedRowsData: [],
				selectedRowKeys: [],
				selecting: {
					mode: 'multiple',
					selectAllMode: 'allPages',
					showCheckBoxesMode: 'always',
				},
			},
		};
	},
	computed: {
		searchValidationGroup: function() {
			return this.$refs['searchValidationGroup'].instance;
		},
	},
	methods: {
		onSelectionChanged(e) {
			this.dataGrid.selectedRowKeys = e.selectedRowKeys;
			this.dataGrid.selectedRowsData = e.selectedRowsData;
		},
		async selectDataList() {
			if (!vm.searchValidationGroup.validate().isValid) {
				return;
			}

			const payload = {
				actionname: 'EWM_PERFORMANCE_STD_SELECT',
				data: {
					startDt: vm.searchType.customTypes.startDt,
					endDt: vm.searchType.customTypes.endDt,
				},
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.dataGrid.dataSource = res.data.data;
			}
		},
		/** @description : 라이프사이클 created 호출되는 메서드 */
		createdData() {
			vm = this;

			this.dataGrid.selectedRowKeys = this.contentData;
			this.searchType.customTypes.startDt = this.searchParams.startDt ? this.searchParams.startDt : getPastFromToday(3, 'months');
			this.searchType.customTypes.endDt = this.searchParams.endDt ? this.searchParams.endDt : getPastFromToday(-3, 'months');
		},
		/** @description : 라이프사이클 mounted 호출되는 메서드 */
		mountedData() {
			this.selectDataList();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>
